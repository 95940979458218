<template>
  <div>
    二维码管理
  </div>
</template>

<script>
export default {
  name: "ewm"
}
</script>

<style scoped>

</style>
